import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout"
import SideNavigation from "../components/sideNavigation/sideNavigation"

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
const CVIndex = (props) => {
  const {  data } = props
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={props.location} title={siteTitle}>
      <SideNavigation location={props.location}></SideNavigation>
    </Layout>
  )
}

export default CVIndex


