import React from "react"
import { Link } from "gatsby"

const Bio = props => {
  var data = props.info.data
  var uid = props.info.uid

  return (
    <Link
      to={`/${props.lang}/${props.brand}/${uid}`}
      activeClassName="active-person"
    >
      <div
        className={
          `mb-6 h-16 pr-5 items-center flex cursor-pointer no-underline hover:shadow-lg border-2 border-transparent hover:border-qlpink rounded-full`
        }
      >
        {data.featured_image !== null ? (
          <img
            width={60}
            height={60}
            src={data.featured_image.url}
            alt={data.name.text}
            className="mr-4 mb-0 rounded-full"
            style={{ objectFit: "cover", height: "60px", width: "60px" }}
          />
        ) : null}
        <article key={uid}>
          <header>
            <h4 style={{'fontSize': '18px'}}>{data.name.text}</h4>
          </header>
          <section>
            <h6>{data.description.text}</h6>
          </section>
        </article>
      </div>
    </Link>
  )
}

export default Bio
