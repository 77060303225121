import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Bio from "./bio"

const SideNavigation = ({ location }) => {
  const query = useStaticQuery(graphql`
    query {
      allPrismicResume(sort: {fields: uid, order: ASC}) {
        edges {
          node {
            uid
            lang
            data {
              name {
                text
              }
              description {
                text
              }
              featured_image {
                url
              }
            }
          }
        }
      }
    }
  `)
  

  let lang =
    location.pathname === "/" ? "en-us" : location.pathname.split("/")[1]
  let brand = location.pathname.split("/")[2]
  const queenPage = location.pathname !== "/"
  
  return (
    <div
      style={{ maxWidth: "1290px" }}
      className={`flex-2 bg-white pb-2 pt-12 print:hidden ${
        queenPage ? "hidden fixed lg:block" : "w-full md:w-md lg:w-full m-auto"
      }`}
    >
      <section
        className={`persons-list ${
          queenPage
            ? "flex-no-wrap"
            : "px-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 xl:gap-16"
        }`}
      >
        {query.allPrismicResume.edges.map(
          ({ node }, i) =>
            node.lang === lang && (
              <Bio
                key={"person-list" + i}
                info={node}
                lang={lang}
                brand={brand || "queenslab"}
                queenPage={queenPage}
              />
            )
        )}
      </section>
    </div>
  )
}

export default SideNavigation
